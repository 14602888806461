/* Importing Select SCSS file. */

/* You can add global styles to this file, and also import other style files */
@import "../node_modules/@ng-select/ng-select/themes/default.theme.css";
@import "ngx-lightbox/lightbox.css";
@import "../node_modules/bootstrap/scss/bootstrap.scss";

@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-quartz.css";

@import "../node_modules/dropzone/dist/min/dropzone.min.css";
